/* Styling for the Gallery  and Favorite components */
 

.gallery-container {
    flex: 1;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgb(8, 2, 36);
    padding: 20px;
    justify-content: center;
}

.heading {
    color: lightgrey;
    font-size: 2.9rem;
    margin: 10px;
    text-align: center  ;
    margin-top: 1.2%;
    text-shadow: 2px 2px 4px lightgrey;
}

h1 {
    color: white;
    font-size: 50px;
    margin-top: 10px;
}



.search input {
    flex: 1;
    padding: 10px;
    margin: 20px;
    border: none;
    border-radius: 10px;
    font-size: 20px;
    width: 50%;
    background-color: lightgrey;
}

.search .navigation {
    margin-right: 20px;
}

.search button {
    
    font-size: 20px ;
}

.navigation {
    font-size: large;
    text-decoration: none;
    color: lightgrey;
    font-size: 30px;
    margin:2ch;
    cursor: pointer;
    text-align: center;
    vertical-align: middle;
    border-radius: 10px;
}

.navigation:hover {
    color: blue;
    
}

.navigation.disabled {
    color: rgb(8, 2, 36); 
    cursor: none;
    cursor: not-allowed; 
    pointer-events: none;
}

.icon {
    vertical-align: middle;
}

p {
    color: white;
    font-size: 40px;
    margin-top: 100px;
}

button {
    color: rgb(8, 2, 36);
    background-color: lightgrey;
    border: none;
    padding:10px;
    border-radius: 10px;
    cursor: pointer;
    font-size: 25px;
    font-weight: bold;
}

.search button:hover {
    background-color: blue;
    color: lightgrey;
}

.search {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-left:7.3ch;
    padding-top: 0px;
    padding-bottom: 1%;
   
   
}



.image-gallery {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 18px;
    width: 100%;
    padding: 15px;
    box-sizing: border-box;
    background-color: rgb(8, 2, 36);
    justify-content: center;
    align-items: center;
    align-content: center ;
    justify-content: center;
}

.image-gallery-favourites {

    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 18px;
    width: 100%;
    max-width: 1200px; 
    margin: 0 auto;
    padding: 20px;
    box-sizing: border-box;
    background-color: rgb(8, 2, 36);
    align-items: center;
}

.image-container {
    position: relative;
    width: 100%;
    padding-top: 75%;
    overflow: hidden;
    border-radius: 5px;
    background-color: rgb(8, 2, 36);
}

.image-container img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    aspect-ratio: 1/1;
    overflow: hidden;
}

.overlay {
    position: absolute;
    bottom: 0px;
    right: 0px;
    width: 30px;
    height: 30px;
    background-color: rgba(8, 2, 36, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
}

.favourite-button {
    background: none;
    border: none;
    color: rgba(255,255,255,0.8);
    cursor: pointer;
    font-size: 17px;
    z-index: 10;
    margin-top: 5px;
    vertical-align: middle;
    align-items: center;
    justify-content: center;
    vertical-align: middle  ;
    text-align: center  ;
}

.favourite-button:focus {
    outline: none;
}

.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    vertical-align: middle;
}

.pagination button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 24px;
    margin: 0 10px;
    color: lightgrey;
    vertical-align: middle;
}
.pagination button:hover {
    color: blue;
}

.page-num {
    margin: 0 10px;
    color: lightgrey;
    font-size: 17px;
}

.pagination button.disabled {
    opacity: 0.2; 
    cursor: not-allowed; 
    pointer-events: none; 
}

@media screen and (max-width: 620px) {

    .heading {
        font-size: 2.1rem; 
        margin-left: 0px;
        margin-right: 0px;
        padding-left: 3px;
        padding-right: 3px;
    }

    .search {
        flex-direction: column; 
        align-items: center; 
        width: 100%;
        justify-content: center;
        align-content: center;
    
    }

    .search input,
    .search button {
        font-size: 1.3rem; 
        width: 80%; 
        text-align: center;
        justify-content: center;
        align-items: center;
        align-content: center;
        padding-left: 0%;

    }

    .search button {
        margin:auto
    }
    
    .search .navigation {
        font-size: 1.6rem; 
        margin: 10px 0; 
        width: 90%; 
        text-align: center; 
        padding-top: 10px; 
    }

    .navigation {
        font-size: 1.1rem; 
        margin: 10px 0; 
        width: 90%; 
        text-align: center; 
    }

    .search {
        padding-left: 0%;
        margin: auto 0
    }

    .image-gallery {
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)); 
    }
}
