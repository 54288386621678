/* Modal Styling */

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 110%;
    background-color: rgba(8, 2, 36, 0.95);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.modal-content {
    background-color: rgb(8, 2, 36);
    padding-bottom:  20px;
    border-radius: 8px;
    width:95%;
    max-height: 80%;
    overflow-y:auto;
    position: relative;
    margin-top: -20;
}

.modal-close {
    position: absolute;
    top: 0px;
    right: 20px;
    font-size: 45px;
    font-weight: bold;
    cursor: pointer;
    color: white;
    z-index: 1101;
}

.modal-border {
    padding: 5px;
   
}

.modal-image {
    max-width: 50%;
    max-height: 65vh;
    height: auto;
    display: block;
    margin: 0 auto 20px;
    border-radius: 8px;
}

.modal-user {
    display: flex;
    flex-direction:row;
    align-items: center;
    margin-bottom: 10px;
    padding: 5px;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    justify-content: center;
    max-width: fit-content;
    padding-left: 4%;
    padding-right: 4%;
    padding-top:0.2%;
    padding-bottom:0.2%;
    margin: 0 auto;
    object-fit: cover;
}

.user-image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 10px;
}

.user-info {
    display: flex;
    flex-direction: column;
    margin: 5px 0;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 18px;
}

.modal-user p {
    font-size: 18px;
    font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
 
}

.modal-user a{
    font-size: 16px;
    font-family:Arial, Helvetica, sans-serif;
    font-style: italic;
    text-decoration: none;
    color: lightgrey;
    padding-top: 50px;
}

.modal-user .navigation:hover{
    cursor: pointer;
}

.modal-user a:hover{
    color: blue;
}

.modal-text {
    margin-top: -50px;
    font-size: 16px;
    color: white;
    text-align: center;
}

.user-profile{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  
}

@media (max-width: 620px) {
    .modal-content {
        width: 90%;
        padding: 10px;
    }
    
    .modal-text {
        font-size: 12px;
    }

    .modal-close {
        top: 0px;
        right: 0px;
    }
    .modal-image {
        max-width: 100%;
    }

    .modal-border {
    
        padding: 0px;
        margin-bottom: 0px;
    }
}
